.root {
  &[data-variant='blackGradient'] {
    background: linear-gradient(180deg, #525252 0%, #000 100%);
    border-radius: var(--mantine-radius-xl);

    :global(.mantine-Button-section) {
      transition: margin-left 0.15s;
    }

    &:hover {
      background: #000;

      :global(.mantine-Button-section) {
        margin-left: calc(var(--_button-section-margin-left) + 5px);
      }
    }
  }
}
