html,
body {
  overflow-x: clip;
}

.syntaxHighlighter {
  border: 1px solid #777;
  border-top: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  font-size: 14;
}

.remark-code-title {
  padding: 0.5em 1em;
  background: #777;
  color: white;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  margin-bottom: -0.5em;
  width: 100%;
  font-size: 14;
  font-weight: bold;

  & + pre {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }
}
