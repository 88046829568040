.m_a513464 {
  --notification-radius: var(--mantine-radius-default);
  --notification-color: var(--mantine-primary-color-filled);

  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 22px;
  padding-right: var(--mantine-spacing-xs);
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
  border-radius: var(--notification-radius);
  box-shadow: var(--mantine-shadow-lg);
}

  .m_a513464::before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    top: var(--notification-radius);
    bottom: var(--notification-radius);
    left: 4px;
    border-radius: var(--notification-radius);
    background-color: var(--notification-color);
  }

  :where([data-mantine-color-scheme='light']) .m_a513464 {
    background-color: var(--mantine-color-white);
}

  :where([data-mantine-color-scheme='dark']) .m_a513464 {
    background-color: var(--mantine-color-dark-6);
}

  .m_a513464:where([data-with-icon]) {
    padding-left: var(--mantine-spacing-xs);
  }

  .m_a513464:where([data-with-icon])::before {
      display: none;
    }

  :where([data-mantine-color-scheme='light']) .m_a513464:where([data-with-border]) {
      border: 1px solid var(--mantine-color-gray-3);
}

  :where([data-mantine-color-scheme='dark']) .m_a513464:where([data-with-border]) {
      border: 1px solid var(--mantine-color-dark-4);
}

.m_a4ceffb {
  box-sizing: border-box;
  margin-right: var(--mantine-spacing-md);
  width: 28px;
  height: 28px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--notification-color);
  color: var(--mantine-color-white);
}

.m_b0920b15 {
  margin-right: var(--mantine-spacing-md);
}

.m_a49ed24 {
  flex: 1 1;
  overflow: hidden;
  margin-right: var(--mantine-spacing-xs);
}

.m_3feedf16 {
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--mantine-font-size-sm);
  line-height: var(--mantine-line-height-sm);
  font-weight: 500;
}

:where([data-mantine-color-scheme='light']) .m_3feedf16 {
    color: var(--mantine-color-gray-9);
}

:where([data-mantine-color-scheme='dark']) .m_3feedf16 {
    color: var(--mantine-color-white);
}

.m_3d733a3a {
  font-size: var(--mantine-font-size-sm);
  line-height: var(--mantine-line-height-sm);
  overflow: hidden;
  text-overflow: ellipsis;
}

:where([data-mantine-color-scheme='light']) .m_3d733a3a {
    color: var(--mantine-color-black);
}

:where([data-mantine-color-scheme='dark']) .m_3d733a3a {
    color: var(--mantine-color-dark-0);
}

:where([data-mantine-color-scheme='light']) .m_3d733a3a:where([data-with-title]) {
      color: var(--mantine-color-gray-6);
}

:where([data-mantine-color-scheme='dark']) .m_3d733a3a:where([data-with-title]) {
      color: var(--mantine-color-dark-2);
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_919a4d88:hover {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_919a4d88:hover {
      background-color: var(--mantine-color-dark-8);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_919a4d88:active {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_919a4d88:active {
      background-color: var(--mantine-color-dark-8);
  }
}

.m_1b7284a3 {
  --paper-radius: var(--mantine-radius-default);

  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  touch-action: manipulation;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: var(--paper-radius);
  box-shadow: var(--paper-shadow);
  background-color: var(--mantine-color-body);
}

  :where([data-mantine-color-scheme='light']) .m_1b7284a3:where([data-with-border]) {
      border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
}

  :where([data-mantine-color-scheme='dark']) .m_1b7284a3:where([data-with-border]) {
      border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

.m_925c2d2c {
  container: simple-grid / inline-size;
}

.m_2415a157 {
  display: grid;
  grid-template-columns: repeat(var(--sg-cols), minmax(0, 1fr));
  grid-gap: var(--sg-spacing-y) var(--sg-spacing-x);
  gap: var(--sg-spacing-y) var(--sg-spacing-x);
}

.m_6d731127 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-items: var(--stack-align, stretch);
  justify-content: flex-start;
  justify-content: var(--stack-justify, flex-start);
  gap: var(--stack-gap, var(--mantine-spacing-md));
}

.m_b23fa0ef {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  line-height: var(--mantine-line-height);
  font-size: var(--mantine-font-size-sm);
  table-layout: auto;
  table-layout: var(--table-layout, auto);
  caption-side: bottom;
  caption-side: var(--table-caption-side, bottom);
  border: none;
}

  :where([data-mantine-color-scheme='light']) .m_b23fa0ef {
    --table-hover-color: var(--mantine-color-gray-1);
    --table-striped-color: var(--mantine-color-gray-0);
    --table-border-color: var(--mantine-color-gray-3);
}

  :where([data-mantine-color-scheme='dark']) .m_b23fa0ef {
    --table-hover-color: var(--mantine-color-dark-5);
    --table-striped-color: var(--mantine-color-dark-6);
    --table-border-color: var(--mantine-color-dark-4);
}

  .m_b23fa0ef:where([data-with-table-border]) {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
  }

  .m_b23fa0ef:where([data-tabular-nums]) {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }

  .m_b23fa0ef:where([data-variant='vertical']) :where(.m_4e7aa4f3) {
    font-weight: 500;
  }

  :where([data-mantine-color-scheme='light']) .m_b23fa0ef:where([data-variant='vertical']) :where(.m_4e7aa4f3) {
      background-color: var(--mantine-color-gray-0);
}

  :where([data-mantine-color-scheme='dark']) .m_b23fa0ef:where([data-variant='vertical']) :where(.m_4e7aa4f3) {
      background-color: var(--mantine-color-dark-6);
}

.m_4e7aa4f3 {
  text-align: left;
}

:where([dir="rtl"]) .m_4e7aa4f3 {
    text-align: right;
}

.m_4e7aa4fd {
  border-bottom: none;
  background-color: transparent;
}

@media (hover: hover) {
    .m_4e7aa4fd:hover:where([data-hover]) {
      background-color: var(--tr-hover-bg);
    }
}

@media (hover: none) {
    .m_4e7aa4fd:active:where([data-hover]) {
      background-color: var(--tr-hover-bg);
    }
}

.m_4e7aa4fd:where([data-with-row-border]) {
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
  }

.m_4e7aa4ef,
.m_4e7aa4f3 {
  padding: var(--table-vertical-spacing) var(--table-horizontal-spacing, var(--mantine-spacing-xs));
}

.m_4e7aa4ef:where([data-with-column-border]:not(:last-child)), .m_4e7aa4f3:where([data-with-column-border]:not(:last-child)) {
    border-right: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
  }

.m_b2404537 > :where(tr):where([data-with-row-border]:last-of-type) {
      border-bottom: none;
    }

.m_b2404537 > :where(tr):where([data-striped='odd']:nth-of-type(odd)) {
      background-color: var(--table-striped-color);
    }

.m_b2404537 > :where(tr):where([data-striped='even']:nth-of-type(even)) {
      background-color: var(--table-striped-color);
    }

.m_b2404537 > :where(tr)[data-hover] {
      --tr-hover-bg: var(--table-highlight-on-hover-color, var(--table-hover-color));
    }

.m_b242d975 {
  top: 0;
  top: var(--table-sticky-header-offset, 0);
  z-index: 3;
}

.m_b242d975:where([data-sticky]) {
    position: sticky;
    background-color: var(--mantine-color-body);
  }

.m_9e5a3ac7 {
  color: var(--mantine-color-dimmed);
}

.m_9e5a3ac7:where([data-side='top']) {
    margin-bottom: var(--mantine-spacing-xs);
  }

.m_9e5a3ac7:where([data-side='bottom']) {
    margin-top: var(--mantine-spacing-xs);
  }

.m_a100c15 {
  overflow-x: var(--table-overflow);
}

.m_62259741 {
  min-width: var(--table-min-width);
}

.m_b6d8b162 {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: var(--text-fz, var(--mantine-font-size-md));
  line-height: var(--text-lh, var(--mantine-line-height-md));
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

  .m_b6d8b162:where([data-truncate]) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .m_b6d8b162:where([data-truncate='start']) {
    direction: rtl;
    text-align: right;
  }

  :where([dir="rtl"]) .m_b6d8b162:where([data-truncate='start']) {
      direction: ltr;
      text-align: left;
}

  .m_b6d8b162:where([data-variant='gradient']) {
    background-image: var(--text-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .m_b6d8b162:where([data-line-clamp]) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--text-line-clamp);
    -webkit-box-orient: vertical;
  }

  .m_b6d8b162:where([data-inherit]) {
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  .m_b6d8b162:where([data-inline]) {
    line-height: 1;
  }

.m_7341320d {
  --ti-size-xs: 18px;
  --ti-size-sm: 22px;
  --ti-size-md: 28px;
  --ti-size-lg: 34px;
  --ti-size-xl: 44px;
  --ti-size: var(--ti-size-md);

  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  width: 28px;

  width: var(--ti-size);
  height: 28px;
  height: var(--ti-size);
  min-width: 28px;
  min-width: var(--ti-size);
  min-height: 28px;
  min-height: var(--ti-size);
  border-radius: var(--ti-radius, var(--mantine-radius-default));
  background: var(--ti-bg, var(--mantine-primary-color-filled));
  color: var(--ti-color, var(--mantine-color-white));
  border: 1px solid transparent;
  border: var(--ti-bd, 1px solid transparent);
}

.m_8a5d1357 {
  margin: 0;
  font-weight: var(--title-fw);
  font-size: var(--title-fz);
  line-height: var(--title-lh);
  font-family: var(--mantine-font-family-headings);
  text-wrap: var(--title-text-wrap, var(--mantine-heading-text-wrap));
}

  .m_8a5d1357:where([data-line-clamp]) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--title-line-clamp);
    -webkit-box-orient: vertical;
  }

.m_d6493fad :first-child {
    margin-top: 0;
  }
  .m_d6493fad :last-child {
    margin-bottom: 0;
  }
  .m_d6493fad :where(h1, h2, h3, h4, h5, h6) {
    margin-bottom: var(--mantine-spacing-xs);
    text-wrap: var(--mantine-heading-text-wrap);
  }
  .m_d6493fad :where(h1) {
    margin-top: calc(1.5 * var(--mantine-spacing-xl));
    font-size: var(--mantine-h1-font-size);
    line-height: var(--mantine-h1-line-height);
    font-weight: var(--mantine-h1-font-weight);
  }
  .m_d6493fad :where(h2) {
    margin-top: var(--mantine-spacing-xl);
    font-size: var(--mantine-h2-font-size);
    line-height: var(--mantine-h2-line-height);
    font-weight: var(--mantine-h2-font-weight);
  }
  .m_d6493fad :where(h3) {
    margin-top: calc(0.8 * var(--mantine-spacing-xl));
    font-size: var(--mantine-h3-font-size);
    line-height: var(--mantine-h3-line-height);
    font-weight: var(--mantine-h3-font-weight);
  }
  .m_d6493fad :where(h4) {
    margin-top: calc(0.8 * var(--mantine-spacing-xl));
    font-size: var(--mantine-h4-font-size);
    line-height: var(--mantine-h4-line-height);
    font-weight: var(--mantine-h4-font-weight);
  }
  .m_d6493fad :where(h5) {
    margin-top: calc(0.5 * var(--mantine-spacing-xl));
    font-size: var(--mantine-h5-font-size);
    line-height: var(--mantine-h5-line-height);
    font-weight: var(--mantine-h5-font-weight);
  }
  .m_d6493fad :where(h6) {
    margin-top: calc(0.5 * var(--mantine-spacing-xl));
    font-size: var(--mantine-h6-font-size);
    line-height: var(--mantine-h6-line-height);
    font-weight: var(--mantine-h6-font-weight);
  }
  .m_d6493fad :where(img) {
    max-width: 100%;
    margin-bottom: var(--mantine-spacing-xs);
  }
  .m_d6493fad :where(p) {
    margin-top: 0;
    margin-bottom: var(--mantine-spacing-lg);
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(mark) {
      background-color: var(--mantine-color-yellow-2);
      color: inherit;
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(mark) {
      background-color: var(--mantine-color-yellow-5);
      color: var(--mantine-color-black);
}
  .m_d6493fad :where(a) {
    color: var(--mantine-color-anchor);
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  @media (hover: hover) {
    .m_d6493fad :where(a):hover {
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }
}
  @media (hover: none) {
    .m_d6493fad :where(a):active {
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }
}
  .m_d6493fad :where(hr) {
    margin-top: var(--mantine-spacing-md);
    margin-bottom: var(--mantine-spacing-md);
    border: 0;
    border-top: 1px solid;
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(hr) {
      border-color: var(--mantine-color-gray-3);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(hr) {
      border-color: var(--mantine-color-dark-3);
}
  .m_d6493fad :where(pre) {
    padding: var(--mantine-spacing-xs);
    line-height: var(--mantine-line-height);
    margin: 0;
    margin-top: var(--mantine-spacing-md);
    margin-bottom: var(--mantine-spacing-md);
    overflow-x: auto;
    font-family: var(--mantine-font-family-monospace);
    font-size: var(--mantine-font-size-xs);
    border-radius: var(--mantine-radius-sm);
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(pre) {
      background-color: var(--mantine-color-gray-0);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(pre) {
      background-color: var(--mantine-color-dark-8);
}
  .m_d6493fad :where(pre) :where(code) {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      color: inherit;
      border: 0;
    }
  .m_d6493fad :where(kbd) {
    --kbd-fz: 12px;
    --kbd-padding: 3px 5px;

    font-family: var(--mantine-font-family-monospace);
    line-height: var(--mantine-line-height);
    font-weight: 700;
    padding: 3px 5px;
    padding: var(--kbd-padding);
    font-size: 12px;
    font-size: var(--kbd-fz);
    border-radius: var(--mantine-radius-sm);
    border: 1px solid;
    border-bottom-width: 3px;
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(kbd) {
      border-color: var(--mantine-color-gray-3);
      color: var(--mantine-color-gray-7);
      background-color: var(--mantine-color-gray-0);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(kbd) {
      border-color: var(--mantine-color-dark-3);
      color: var(--mantine-color-dark-0);
      background-color: var(--mantine-color-dark-5);
}
  .m_d6493fad :where(code) {
    line-height: var(--mantine-line-height);
    padding: 1px 5px;
    border-radius: var(--mantine-radius-sm);
    font-family: var(--mantine-font-family-monospace);
    font-size: var(--mantine-font-size-xs);
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(code) {
      background-color: var(--mantine-color-gray-0);
      color: var(--mantine-color-black);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(code) {
      background-color: var(--mantine-color-dark-5);
      color: var(--mantine-color-white);
}
  .m_d6493fad :where(ul, ol):not([data-type='taskList']) {
    margin-bottom: var(--mantine-spacing-md);
    padding-left: var(--mantine-spacing-xl);
    list-style-position: outside;
  }
  .m_d6493fad :where(table) {
    width: 100%;
    border-collapse: collapse;
    caption-side: bottom;
    margin-bottom: var(--mantine-spacing-md);
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(table) {
      --table-border-color: var(--mantine-color-gray-3);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(table) {
      --table-border-color: var(--mantine-color-dark-4);
}
  .m_d6493fad :where(table) :where(caption) {
      margin-top: var(--mantine-spacing-xs);
      font-size: var(--mantine-font-size-sm);
      color: var(--mantine-color-dimmed);
    }
  .m_d6493fad :where(table) :where(th) {
      text-align: left;
      font-weight: bold;
      font-size: var(--mantine-font-size-sm);
      padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
    }
  .m_d6493fad :where(table) :where(thead th) {
      border-bottom: 1px solid;
      border-color: var(--table-border-color);
    }
  .m_d6493fad :where(table) :where(tfoot th) {
      border-top: 1px solid;
      border-color: var(--table-border-color);
    }
  .m_d6493fad :where(table) :where(td) {
      padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
      border-bottom: 1px solid;
      border-color: var(--table-border-color);
      font-size: var(--mantine-font-size-sm);
    }
  .m_d6493fad :where(table) :where(tr:last-of-type td) {
      border-bottom: 0;
    }
  .m_d6493fad :where(blockquote) {
    font-size: var(--mantine-font-size-lg);
    line-height: var(--mantine-line-height);
    margin: var(--mantine-spacing-md) 0;
    border-radius: var(--mantine-radius-sm);
    padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
  }
  :where([data-mantine-color-scheme='light']) .m_d6493fad :where(blockquote) {
      background-color: var(--mantine-color-gray-0);
}
  :where([data-mantine-color-scheme='dark']) .m_d6493fad :where(blockquote) {
      background-color: var(--mantine-color-dark-8);
}

html,
body {
  overflow-x: clip;
}

.syntaxHighlighter {
  border: 1px solid #777;
  border-top: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  font-size: 14;
}

.remark-code-title {
  padding: 0.5em 1em;
  background: #777;
  color: white;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  margin-bottom: -0.5em;
  width: 100%;
  font-size: 14;
  font-weight: bold;
}

.remark-code-title + pre {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }

@font-face {
font-family: 'inter';
src: url(/_next/static/media/055fe2efe01b0ce2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/161ec9b856c3b8c6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/816386e8342179fd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/dbab90fb9d6f23d1-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'inter Fallback';src: local("Arial");ascent-override: 98.56%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_650d79 {font-family: 'inter', 'inter Fallback'
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/055fe2efe01b0ce2-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/161ec9b856c3b8c6-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/d49761e5568bcdac-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/fd03ea0e74521041-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/816386e8342179fd-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/dbab90fb9d6f23d1-s.p.ttf) format('truetype');
font-display: auto;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'inter Fallback';src: local("Arial");ascent-override: 98.56%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_493040 {font-family: 'inter', 'inter Fallback', inter, system-ui
}

.Button_root__p9aFi[data-variant='blackGradient'] {
    background: linear-gradient(180deg, #525252 0%, #000 100%);
    border-radius: var(--mantine-radius-xl);
  }

    .Button_root__p9aFi[data-variant='blackGradient'] .mantine-Button-section {
      transition: margin-left 0.15s;
    }

    .Button_root__p9aFi[data-variant='blackGradient']:hover {
      background: #000;
    }

    .Button_root__p9aFi[data-variant='blackGradient']:hover .mantine-Button-section {
        margin-left: calc(var(--_button-section-margin-left) + 5px);
      }

.Text_root__KAnip {
  --mantine-font-size-f36: calc(2.25rem * var(--mantine-scale));
  --mantine-font-size-f24: calc(1.5rem * var(--mantine-scale));
  --mantine-font-size-pageTitle: calc(6rem * var(--mantine-scale));

  --mantine-line-height-f36: calc(2.75rem * var(--mantine-scale));
  --mantine-line-height-f24: calc(2rem * var(--mantine-scale));
  --mantine-line-height-pageTitle: 90%;
}

  @media (max-width: 48em) {.Text_root__KAnip {
    --mantine-font-size-f36: calc(1.875rem * var(--mantine-scale));
    --mantine-font-size-f24: calc(1.25rem * var(--mantine-scale));
    --mantine-font-size-pageTitle: calc(2.75rem * var(--mantine-scale));

    --mantine-font-size-lg: calc(1rem * var(--mantine-scale));
    --mantine-font-size-md: calc(0.875rem * var(--mantine-scale));

    --mantine-line-height-f36: calc(1.875rem * var(--mantine-scale));
    --mantine-line-height-f24: calc(1.5rem * var(--mantine-scale));
    --mantine-line-height-pageTitle: calc(3rem * var(--mantine-scale));

    --mantine-line-height-xl: 24px;
    --mantine-line-height-lg: 24px
}
  }

.Footer_footer__4vzqH {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: 0;
  background-color: var(--mantine-color-shepherdGray-1);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__4vzqH {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}
  .Footer_footer__4vzqH {
  position: relative;
}

.Footer_logo__C9vo8 {
  max-width: calc(12.5rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_logo__C9vo8 {
    display: flex;
    flex-direction: column;
    align-items: center
}
  }

.Footer_description__s_1Tl {
  margin-top: calc(0.3125rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_description__s_1Tl {
    margin-top: var(--mantine-spacing-xs);
    text-align: center
}
  }

.Footer_inner__FbJ6a {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {

.Footer_inner__FbJ6a {
    flex-direction: column
    /* align-items: center; */
}
  }

.Footer_groups__y7eTr {
  display: flex;
  flex-wrap: wrap;
}

.Footer_wrapper__rpDYh {
  width: calc(10rem * var(--mantine-scale));
}

.Footer_link__wByfM {
  display: block;
  color: var(--mantine-color-gray-6);
}

[data-mantine-color-scheme='dark'] .Footer_link__wByfM {
  color: var(--mantine-color-dark-1);
}

.Footer_link__wByfM {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
}

.Footer_title__D7snR {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family: Greycliff CF, var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__D7snR {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__Bk3gc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .Footer_afterFooter__Bk3gc {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

@media (max-width: 48em) {

.Footer_afterFooter__Bk3gc {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__RYYyg {
    margin-top: var(--mantine-spacing-xs)
}
  }

.ShepherdHeader_header__DlfMd {
  /* height: rem(20px); */
  /* margin-bottom: rem(120px); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.24); */
  background: rgba(255, 255, 255, 0.3);
  /* backdrop-filter: blur(24px); */
  position: relative;
  z-index: 99;
  transition: background 300ms ease;
}

.ShepherdHeader_headerOpened__TT0bT {
  /* background: white; */
}

.ShepherdHeader_inner__c9Fr4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 62px;
}

.ShepherdHeader_link__lfufz {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--mantine-color-gray-9);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 20px;
}

.ShepherdHeader_link__lfufz:hover {
    opacity: 0.5;
  }

.ShepherdHeader_linkLabel__pxGvf {
  margin-right: calc(0.3125rem * var(--mantine-scale));
}

.ShepherdHeader_drawer__LWTTD {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0px;
  background: white;

  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  transition: height 400ms ease;
}

.ShepherdHeader_drawerInner__RLaWd {
  padding: 16px;
  width: 100%;
}

.ShepherdHeader_drawerOpen__mUNHr {
  height: calc(100vh - 62px);
  border-top: 1px solid var(--mantine-color-gray-2);
}

.ShepherdHeader_menuItem__m66Ih {
  display: block;
  border-radius: 8px;
  background-color: white;
  cursor: auto;
}

.ShepherdHeader_menuItemHover__JKY4F {
  cursor: pointer;
}

.ShepherdHeader_menuItemHover__JKY4F:hover {
    background-color: var(--mantine-color-shepherdGray-2);
  }

