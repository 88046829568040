.root {
  --mantine-font-size-f36: rem(36);
  --mantine-font-size-f24: rem(24);
  --mantine-font-size-pageTitle: rem(96);

  --mantine-line-height-f36: rem(44);
  --mantine-line-height-f24: rem(32);
  --mantine-line-height-pageTitle: 90%;

  @media (max-width: $mantine-breakpoint-sm) {
    --mantine-font-size-f36: rem(30);
    --mantine-font-size-f24: rem(20);
    --mantine-font-size-pageTitle: rem(44);

    --mantine-font-size-lg: rem(16);
    --mantine-font-size-md: rem(14);

    --mantine-line-height-f36: rem(30);
    --mantine-line-height-f24: rem(24);
    --mantine-line-height-pageTitle: rem(48);

    --mantine-line-height-xl: 24px;
    --mantine-line-height-lg: 24px;
  }
}
