.header {
  /* height: rem(20px); */
  /* margin-bottom: rem(120px); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.24); */
  background: rgba(255, 255, 255, 0.3);
  /* backdrop-filter: blur(24px); */
  position: relative;
  z-index: 99;
  transition: background 300ms ease;
}

.headerOpened {
  /* background: white; */
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 62px;
}

.link {
  text-decoration: none;
  color: var(--mantine-color-gray-9);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 16px 20px;

  &:hover {
    opacity: 0.5;
  }
}

.linkLabel {
  margin-right: rem(5px);
}

.drawer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0px;
  background: white;

  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  transition: height 400ms ease;
}

.drawerInner {
  padding: 16px;
  width: 100%;
}

.drawerOpen {
  height: calc(100vh - 62px);
  border-top: 1px solid var(--mantine-color-gray-2);
}

.menuItem {
  display: block;
  border-radius: 8px;
  background-color: white;
  cursor: auto;
}

.menuItemHover {
  cursor: pointer;
  &:hover {
    background-color: var(--mantine-color-shepherdGray-2);
  }
}
